var HOST = "";
if (window.location.hostname === "localhost" || window.location.hostname === "[::1]" || window.location.hostname.match(/^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)) {
  HOST = `https://gateway-api-develop.virace.vn/api/`;
  //HOST = `https://localhost:44397/api/`;
} else if (window.location.host.includes("viracresearch")) {
  // HOST = `https://gateway-api.virace.vn/api/`;
  HOST = "https://legacy-gateway-api.virace.vn/api/";
} else if (window.location.host.includes("web-develop.virace")) {
  HOST = `https://gateway-api-develop.virace.vn/api/`;
} else if (window.location.host.includes("pre-production")) {
  HOST = `https://pre-production-api.virace.vn/api/`;
} else {
  // HOST = `https://gateway-api-develop.virace.vn/api/`;
  // HOST = `https://gateway-api.virace.vn/api/`;
  HOST = "https://legacy-gateway-api.virace.vn/api/";
}

export default HOST;
